.closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
  border: 0;
  background-color: transparent;
  font-weight: 700;
  font-size: 20px;
  transition: opacity .1s ease-in-out;
  text-align: right;
  &:hover {
    opacity: .5;
    cursor: pointer;
  }
}

.navContainer { 
  display: flex;
  justify-content: space-between;
}

.navArrow {
  border: 0;
  background-color: transparent;
  transition: opacity .1s ease-in-out;
  &:hover {
    opacity: .5;
    cursor: pointer;
  }
}

.navDots { 
  display: flex;
  justify-content: center;
  align-items: center;
}

.navDot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #CDCDCD;
  margin: 0 5px;
}



.popover {
  border-radius: 10px;
  text-align: center;
  color: #000;
  @media (min-width: 768px) {
   font-size: 14px;  
  }
  font-size: 13px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  text-align: center;
  h3 {
    @media (min-width: 768px) {
      font-size: 18px;  
     }
    font-size: 16px;
    font-weight: 700;
  }
  p {
    padding-bottom: .5rem;
    span {
      font-weight: 700;
    }
  }
} 