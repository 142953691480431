.mobileItem {
  font-size: 17px;
  font-weight: 700;
}

// wrapper for logo image, the 3 classes below per brand are applied
// to the images
.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
}