.pathToSettlementBorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #f8f9fc;
  background: #fff;
  box-shadow: 0px 5px 6px -2px rgba(16, 24, 40, 0.04),
    0px 3.3px 18px -3px rgba(16, 24, 40, 0.09);
  padding: 25px 16px;
  justify-content: center;
  margin-bottom: 16px;
}

.pathToSettlementContent {
  max-width: 335px;
}

.headingCard {
  display: flex;
  justify-content: center;
}
