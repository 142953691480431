.cardContainer {
  background-color: #d2e2fe;
  padding: 16px 16px 8px;
  border-radius: 8px;
  @media (min-width: 768px) {
    max-width: 300px;
  }
  margin-bottom: 16px;
}

.cardHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4px;
}

.closeIcon {
  flex-basis: 40px;
}

.cardContent {
  padding-top: 8px;
  margin: 8px;
}
