$small: 450px;

.contentWrapper {
  margin: 2rem 0rem;
}

.logo {
  margin-left: 2rem;
  align-self: left;
}

.modalHeading {
  margin-left: 2rem;
  padding: 1rem 0;
  width: 240px;
}

.modalMessage {
  margin: 0 30px 32px 2rem;
  letter-spacing: normal;
}

.error {
  display: block;
  margin-left: 2rem;
}

.buttonGroupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  .buttonWrapper{
    width: 135px;
    margin-right: 1rem;
    &:first-child {
      order: 1;
      margin-top: 1rem;
    }
  }
  @media screen and (min-width: $small) {
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 2rem;
    .buttonWrapper {
      &:first-child {
        order: 0;
        margin: 0 1rem 0 0; 
      }
      &:last-child {
        margin-right: 0rem;
      }
    }
  }
}