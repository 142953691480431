$large: 1100px;
$medium: 800px;
// General Layout

.footer {
  margin: auto;
  max-width: 1170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 36px 32px;
  @media screen and (min-width: $medium) {
    padding: 67px;
  }
}

.upperFooter, .lowerFooter {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (min-width: $medium) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.upperFooter {
  justify-content: space-between;
  // below helps with spacing between sections
  & > div {
    margin-right: 25px;
    @media screen and (min-width: $large) {
      margin-right: 80px;
    }
  }
  @media screen and (min-width: $medium) {
    height: 285px;
    margin-bottom: 12px;
  }
}

.lowerFooter {
  @media screen and (min-width: $medium) {
    justify-content: space-between;
    margin-top: 24px;
  }
}

// Common Layout For All Upper Footer Sections

.contact, .branding, .linkSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

// Logo and Brand Section | Top Left

.logo {
  margin-bottom: 33px;
  @media screen and (min-width: $medium) {
    margin-bottom: 45px;
}
  img {
    width: 64px;
  }
}

.branding {
  max-width: 359px;
  margin-bottom: 22px;
  @media screen and (min-width: $medium) {
    margin-bottom: 0px;
    max-width: 310px;
  }
}

// Contact Section | Top Center

.phoneLine {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 36px;
  @media screen and (min-width: $medium) {
    margin: 24px 0px 0px;
  }
}

.phoneIcon {
  margin-right: 10px;
}

// Link Section | Top Right

.linkSection {
  margin-bottom: 34px;
  @media screen and (min-width: $medium) {
    margin-bottom: 0px;
  }
}

.linkSectionHeading {
  margin-bottom: 10px;
  @media screen and (min-width: $medium) {
    margin-bottom: 24px;
  }
}

.link {
  margin-bottom: 6px;
  a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 8px;
  }
}

hr {
  border: 0;
  @media screen and (min-width: $medium) {
    border: 1;
    width: 100%;
    margin-top: 16px;
  }
}

.copyright {
  margin-bottom: 48px;
  @media screen and (min-width: $medium) {
    margin-bottom: 0px;
  }
}