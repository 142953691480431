.socialIconsWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.socialIcon {
  display: block;
  position: relative;
  min-height: 24px;
  min-width: 24px;
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }
}

.iconContainer {
  display: flex;
  min-height: 24px;
  min-width: 24px;
  position: absolute;
}

.iconGray img {
  opacity: 1;
  position: absolute;
}

.iconColor img {
  opacity: 0;  
  position: absolute;
}

.iconGray:hover img {
  opacity: 0;
}

.iconColor:hover img {
  opacity: 1;
}