.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.accordionContent,
.accordionContentExpanded {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.25s ease-out;
}

.accordionContentExpanded {
  overflow: hidden;
  transition: all 0.25s ease-out;
  opacity: 1;
  max-height: 800px;
}

.button {
  text-align: center;
  transform: scale(0.8);
  transition: opacity 0.25s ease-out;
  &:hover {
    opacity: 0.8;
  }
}
