$breakpoint-mobile: 750px;
@import './Palette.module.scss';

.programDetailsBorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $neutral-25;
  background: $neutral-0;
  box-shadow: 0px 5px 6px -2px $color-shadow1,
    0px 3.3px 18px -3px $color-shadow2;
  padding: 25px 16px;
  justify-content: center;
  margin-bottom: 16px;
}

.programDetailsHeading {
  width: 307px;
  .subHeading {
    margin-bottom: 30px;
    p {
      margin: 12px 0px 20px;
    }
  }
  @media (min-width: $breakpoint-mobile) {
    width: 100%;
    text-align: center;
    .subHeading {
      margin: 12px 0px;
      p {
        margin: 0px;
      }
    }
  }
}

.programDetailsCharts {
  width: 307px;
  hr {
    border: 1px solid $neutral-75;
    margin: 0;
  }
}

.accordionContainer {
  margin-bottom: 32px;
}

.finePrint {
  margin-bottom: 32px;
}
