.main {
  padding: 1rem;
}

.main * {
  font-size: 14px;
  font-family: ultramarine, sans-serif;
}

.redBorder {
  border: 1px solid #cb000e;
  padding: 1rem 2.5rem;
}

ol {
  padding-left: 0;
  li {
    margin-left: 0;
    margin-bottom: 1rem;
  }
}

.greyBorder {
  border-top: 1px solid #e7ecf3;
  padding-bottom: 1rem;
}

.contactMessage {
  margin-top: 3rem;
}
