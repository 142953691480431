// note, the various padding and margin 0 styles are to override defaults applied by MUI
// including an unusual 75px padding-top
.coreQuestionWrapper {
  &:not(:first-of-type) {
    padding-top: 0;
  }
  &:first-of-type {
    border-top: 0; // removes possible border from the top of the first question
  }
  &:not(:last-child) {
    margin-bottom: 16px; // margin between core questions
  }
  border-bottom: 0; // overrides MUI border, border is set dynamically through sx prop
}

.coreQuestionSummary {
  min-height: fit-content; // overrides min-height 48px set in MUI
  padding: 0;
  span {
    color: #000; // overrides blue default color
  }
  > * {
    margin-top: 0; // overrides margin between accordions set in MUI
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.coreQuestionDetails {
  padding: 0;

  ul,
  ol {
    padding-inline-start: 20px; // overrides default list indentation
  }
  *:last-child {
    margin-bottom: 0; // cleans up any extra default margin
  }
}
