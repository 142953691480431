.feedbackContainer {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 10px;
  border: 1px solid var(--neutral-75, #e7ecf3);
}
.btnContainer {
  display: flex;
  align-items: flex-start;
  gap: 28px;
}
.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
