.alertCardContainer {
  background-color: #fff5f8;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin: 16px auto;
  }
}
.alertCardHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4px;
}
.alertCardContent {
  padding-top: 10px;
  margin: 8px;
}
.alertCardContactBlock {
  padding: 8px 0px 0px 0px;
  gap: 8px;
  border-top: 1px #c0cbd8 solid;
}
