$large: 1100px;
$medium: 800px;
// General Layout

.footer {
  margin: auto;
  max-width: 1170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 36px 32px;
  @media screen and (min-width: $medium) {
    padding: 67px;
  }
}

.brandingFooter, .tradeMarkText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (min-width: $medium) {
    flex-direction: row;
    align-items: flex-start;
  }
}


.tradeMarkText {
  @media screen and (min-width: $medium) {
    justify-content: space-between;
    margin-top: 24px;
  }
}

 .branding {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-width: 359px;
  margin-bottom: 22px;
  @media screen and (min-width: $medium) {
    margin-bottom: 0px;
    max-width: 310px;
  }
}

.brandingCopy {
  margin-bottom: 15px;
}

.logo {
  img {
    width: 64px;
  }
}

.contactUsText {
  margin:15px 0;
}

.link {
  margin-bottom: 6px;
  a {
    text-decoration: none;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 8px;
  }
}

hr {
    border: 1;
    width: 100%;
    margin-top: 16px;
}

.copyright {
  margin-bottom: 48px;
  @media screen and (min-width: $medium) {
    margin-bottom: 0px;
  }
}