$medium: 720px;
.alertWrapper {
  background-color: #FFF;
  padding: 2rem;
  h4 {
    margin: 0 0 1rem;
  }
}

.alertCopy {
  p {
    margin: 4px 0;
  }
}
.alertCurrentPhoneNumber {
  border: 1px solid #BEC6D5; 
  border-radius: 8px;
  border-left: 10px solid #F7D05E;
  margin-top: 2rem;
  padding: 0.25rem 1rem 0.75rem;
  width: 255px;
  p {
    margin: 0;
    padding: 4px 0
  }   
}

.alertButtons {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  Button {
    &:first-child {
      order: 2;
    }
    &:last-child {
      margin-bottom: 1rem;
      order: 1;
    }
  }
  @media screen and (min-width: $medium) {
    flex-direction: row;
    margin: 3rem 0rem;
    Button {
      &:first-child {
        order: 1;
        margin-right: 0.5rem;
      }
      &:last-child {
        margin-bottom: 0rem;
        margin-left: 0.5rem;
        order: 2;
      }
    }
  }
}

.alertFinePrint {
  color: #616A7C;
}