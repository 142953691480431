@import '../Palette.module.scss';

.section {
  margin: 28px 0px 34px;
}

.sectionHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.sectionSup sup {
  color: $primary-trust-light-blue;
  font-size: 10px;
  font-weight: 600;
}

.sectionTitle {
  padding-right: 4px;
}
