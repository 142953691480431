.authorizeSectionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  
}
.authorizeSectionWrapper > * {
  margin-bottom: 2rem;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 850px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.buttonWrapper, .link, button {
  width: 100%;
}

.link {
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
}