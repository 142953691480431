.main {
  line-height: 19.6px;
  ul {
    padding-top: 0;
    list-style-type: disc;
    li {
      list-style-position: inside;
      margin-bottom: 0;
    }
  }
  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
}

.noDecorationLastItem {
  li:last-child {
    list-style-type: none;
  }
}

.faqSection p {
  margin: 0;
}

.indentedSection {
  padding-left: 36px;
}

.closerUl ul {
  padding-left: 12px;
}
