@import '../Palette.module.scss';

// this class if dynamically applied to the row that is a better deal for the customer
.isHighlighted {
  color: $green-300;
  .chartBar {
    background-color: $green-300;
    color: #fff;
  }
  .rowLabel {
    font-weight: 600;
  }
}

.row {
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  .rowLeft {
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
}
.rowLabel {
  display: flex;
  line-height: 16px;
  text-align: right;
}

.rowRight {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.chartBar {
  display: flex;
  background: $neutrals-greys-grey-5;
  color: #000;
  padding-left: 8px;
  flex-direction: column;
  justify-content: center;

  .unit {
    line-height: 16px;
  }
}
.checkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.finePrint {
  color: $content-secondary;
  ul {
    margin: 0;
    padding-left: 24px;
  }
}
