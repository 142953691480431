.programDetailsFooter {
  color: #1D252F;
  margin: 20px 15px;
  p {
    line-height: 2;
    margin: 0;
  }
  ol {
    line-height: 2;
    margin-top: 18px;
    list-style-position: inside; // places the list decoration (numbers) inside the content box
    padding-left: 0px;
  }
  @media (min-width: 768px) {
    margin: 64px 15px;
    p, ol {
      line-height: inherit;
    }
  }
}